@import "../../sass/base";

$card-margin: 1rem;

$card-top-height: 2rem;
$card-bottom-height: calc(100% - #{$card-top-height});

.flex-parent-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-parent-justified {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.card-behind-border {
  border: 1px solid rgba($color: $color-secondary, $alpha: 0.2);
  background-color: rgba($color: $color-secondary, $alpha: 0.1);
  border-radius: $border-radius-default;
}

.business__card {
  border-radius: $border-radius-default;
  background-color: $color-div-default-bg;
  padding: 24px 30px 30px;
  border: 1px solid rgba($color: $color-secondary, $alpha: 0.2);
  position: relative;
  top: -0.5rem;
  left: 0.5rem;

  .card-title {
    margin-bottom: 24px;

    h2 {
      font-size: 24px;
      margin-bottom: 2px;
    }

    .biz-link {
      display: flex;
      align-items: center;
      width: fit-content;
      font-weight: 700;

      svg {
        margin-left: 6px;
        display: none;
      }

      &:hover,
      &:focus {
        svg {
          display: block;
        }
      }
    }
  }

  .card-details {
    .covid-updates {
      margin-bottom: 1.5rem;
      overflow-wrap: break-word;

      .biz-info-source {
        opacity: 0.7;
      }
    }

    .edit-biz-btn {
      margin-left: 2rem;
    }

    .business-tags {
      display: flex;
      align-items: center;
      justify-content: space-between;
      button.single-tag {
        margin: 0.25rem 0.5rem 0.25rem 0;
        font-size: 1rem;
        background-color: rgba($color: $color-primary, $alpha: 0.1);
        padding: 0.25rem 1rem;
        border-radius: 1rem;
        line-height: 1.25rem;

        > span {
          font-weight: 500;
        }

        .MuiTouchRipple-child {
          background-color: rgba($color: $color-primary, $alpha: 0.8);
        }
      }
    }
  }

  @media (max-width: $breakpoint-sm) {
    padding: 1.2rem 1rem 1.2rem 1.5rem;
  }

  // styles for the loader skeleton
  &.loader {
    .MuiSkeleton-root {
      border-radius: 2rem;
    }

    .loader-top {
      .MuiSkeleton-root {
        background-color: rgba($color: $color-primary, $alpha: 0.2);
      }
    }

    .loader-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > div {
        width: 90%;
        display: flex;
        justify-content: flex-start;

        > span {
          margin-right: 0.5rem;
        }
      }

      .MuiSkeleton-root {
        background-color: rgba($color: $color-primary, $alpha: 0.2);
      }
    }
  }
}
