// Colors

$color-primary: #3455DB;
$color-secondary: #242038;
$color-accent: #FFC1A6;
$color-app-bg: #F4F4F6;
$color-success-bg: #DEEBDB;

// $color-lt-gray: #eaeaea;
// $color-md-gray: darken($color-lt-gray, 10);
// $color-dk-gray: darken($color-lt-gray, 30);

$color-div-default-bg: #fff;
