@import "../../sass/base";

.botDetected {
  h1 {
    font-weight: 600;
  }

  .brand-link {
    font-weight: 600 !important;
    text-decoration: none !important;

    span {
      font-weight: 600;
    }
  }
}
