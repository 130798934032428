@import "../../sass/base";

.MuiAlert-outlinedSuccess {
  background-color: $color-success-bg !important;
  border: 1px solid #ABCCA3 !important;
  padding: 1rem 2rem !important;

  .MuiAlert-icon {
    color: #68995C !important;
    padding: 8px 0 !important;
  }
}

.success-caption {
  padding: 0 30px 0 60px;

  @media (max-width: $breakpoint-sm) {
    padding: 0;
  }
}
