@import "../../sass/base";

.header {
  position: relative;
  @media screen and (min-width: 992px) {
    max-width: 340px;
    padding: 40px;
  }
  @media screen and (max-width: 991px) {
    width: 100%;
    padding: 16px 24px 8px;
  }

  .intro-block-left {
    .intro-top {
      @media screen and (max-width: 991px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .app-logo {
        max-width: 122px;
        @media screen and (max-width: 991px) {
          max-width: 50px;
        }
      }
      .navbar-links {
        margin: 0;
        list-style: none;
      }

      h1 {
        font-size: 24px;
        font-weight: 600;
        margin-top: 24px;
      }

      p {
        margin-top: 12px;
        margin-bottom: 24px;
      }
    }
    .intro-bottom {
      position: absolute;
      bottom: 32px;
      // left: 60px;

      .navbar-links {
        display: flex;
        order: 2;
        list-style: none;
        align-items: center;
        padding-left: 0;
        margin-bottom: 6px;

        li {
          margin-right: 8px;
        }
      }
    }
  }
}
