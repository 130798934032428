@import "../../sass/base";

.searchbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: space-between;
  flex-wrap: nowrap;
  border-radius: 12px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);

  div {
    flex-grow: 2;
  }

  .MuiInputBase-root {
    input {
      padding: 17px 20px;
      border: 2px solid transparent;

      &:focus {
        border: 2px solid $color-primary;
        border-radius: 12px 0 0 12px;
      }
    }
    .MuiSelect-outlined.MuiSelect-outlined {
      padding: 17px 32px 17px 20px;
      border: 2px solid transparent;

      &:focus {
        border: 2px solid $color-primary;
      }
    }

    fieldset {
      border: none;
    }
  }

  .MuiSelect-root {
    width: 100%;
    padding-left: 0;
  }

  .MuiIconButton-root {
    border-radius: 0 12px 12px 0;
    height: 57px;
    background-color: $color-primary;

    &:hover {
      background-color: darken($color-primary, 10%);
    }

    &:focus-visible {
      background-color: red;
    }

    .MuiTouchRipple-child {
      background-color: white;
    }

    .MuiSvgIcon-root {
      color: #fff;
    }
  }
}

.MuiListSubheader-root.city-dropdown-message {
  padding-bottom: 8px;
  padding-top: 4px;
  font-size: 12px;
  line-height: 16px;
  font-style: italic;
}

.MuiMenuItem-root {
  @media (max-width: $breakpoint-sm) {
    white-space: normal;
  }
}
