@import "../../sass/base";

body.welcome-only {
  background: url("../../assets/welcome-bg.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: $color-app-bg;
}

.welcome {
  &.MuiContainer-maxWidthSm {
    max-width: 800px;
  }

  h1 {
    font-size: 40px;
    font-weight: 600;
    line-height: 44px;
  }

  .MuiButton-root {
    padding: 14px 40px;
  }

  .App-logo {
    height: 60px;
    margin-right: 8px;
  }

  ul {
    padding-left: 0;
    margin-top: 10px;
    list-style: none;

    li::before {
      content: "♥️";
      display: inline-block;
      margin-right: 0.2rem;
    }
  }

  .img-fluid {
    width: 100%;
  }
}
