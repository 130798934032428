
$border-radius-default: 12px;
$layout-spacing-10: 10px;

$content-padding-default: 48px;

// breakpoint variable should match value in theme js file
// added here to use as a variable for media queries
$breakpoint-sm: 740px;

$brandfont: "Quicksand",sans-serif;
$bodyfont: "Montserrat", sans-serif;
