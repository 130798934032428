@import "../../sass/base";

html {
  height: 100%;
}

body {
  // background: $color-app-bg;
  // background-image: linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%);
  background-color: #faf2eb;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-weight: 400;
}

.App {
  display: flex;
  @media screen and (min-width: 992px) {
    height: 100vh;
  }
  @media screen and (max-width: 991px) {
    flex-wrap: wrap;
    align-items: flex-start;
  }
}

.MuiFab-root.visible-md {
  position: fixed;
  z-index: 999;
  bottom: 30px;
  right: 30px;
}

.header {
  @media screen and (min-width: 992px) {
    flex: 0 0 auto;
  }
  @media screen and (max-width: 991px) {
    // width: 100%;
  }
}

.main {
  padding: 0 24px;
  flex: 1 1 auto;
  overflow: auto;

  .content {
    @media screen and (min-width: 992px) {
      max-width: 700px;
      margin-top: 32px;
    }
  }
  @media screen and (max-width: 991px) {
    // width: ;
  }
}

.ls-fs-dialog {
  header {
    background-color: $color-div-default-bg;
  }

  .intro-top {
    margin-top: 56px;
    padding: 40px 24px 60px;
    text-align: center;
    height: 100%;
    position: relative;
    max-width: 320px;
    margin: 56px auto 0;

    .app-logo {
      max-width: 112px;
    }

    h1 {
      font-weight: 600;
      margin: 10px auto;
    }

    .navbar-links {
      list-style: none;
      margin-top: 24px;
      padding: 0;

      li {
        display: inline-block;
        padding: 0 10px;
      }
    }
    .bottom-aligned-text {
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.layout-margin-top-base {
  margin-top: 16px;
}

.layout-margin-top-basex2 {
  margin-top: 32px;
}

.logotype {
  span {
    font-weight: 700;
  }
}

a {
  font-weight: 600;
}

.MuiButton-contained {
  .header & {
    padding: 10px 30px 12px;
    font-size: 14px;
  }
}

.img-fluid {
  width: 100%;
  height: auto;
}

.img-top-banner {
  width: 100%;
  overflow: hidden;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
}

.paper-padding {
  padding: $content-padding-default;

  @media (max-width: $breakpoint-sm) {
    padding: calc(#{$content-padding-default} / 2);
  }
}

.paper-padding-x {
  padding: 0 $content-padding-default;

  @media (max-width: $breakpoint-sm) {
    padding: 0 calc(#{$content-padding-default} / 2);
  }
}

.paper-padding-b {
  padding-bottom: $content-padding-default;

  @media (max-width: $breakpoint-sm) {
    padding-bottom: calc(#{$content-padding-default} / 2);
  }
}

.MuiContainer-maxWidthLg {
  // padding for mobile
  @media (max-width: $breakpoint-sm) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

#add-form-disclosure {
  display: none;

  &.reveal-form {
    display: block;
    opacity: 0;
  }

  &.transition {
    animation: appear 0.8s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

// Tag styles for the Add page
.tags-list-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .tags-logo {
    order: 1;
    max-height: 24px;
    margin-right: 8px;

    img {
      height: 24px;
      width: 24px;
    }
  }

  .tags-title {
    order: 2;
  }
}

.form-info-alert {
  &.MuiAlert-root {
    font-size: 12px;
  }

  .MuiAlert-icon {
    font-size: 20px;
  }
}
