// classes and variables to help with layouts

.hidden-xs {
  @media (max-width: $breakpoint-sm) {
    display: none;
  }
}

.hidden-md {
  @media (max-width: 991px) {
    display: none;
  }
}

.visible-md {
  @media (min-width: 992px) {
    display: none !important;
  }
}

.visible-xs {
  @media (min-width: $breakpoint-sm) {
    display: none;
  }
}
