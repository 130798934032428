// styles to help override material-ui
// add here ONLY when true Mui theming isn't an option

.custom-button-padding {
  padding: 14px 40px !important;

  @media (max-width: $breakpoint-sm) {
    padding: 12px 20px !important;
  }
}

.MuiChip-root.MuiAutocomplete-tag.MuiChip-deletable {
  background-color: lighten($color-primary, 40%);
  color: $color-secondary;

  .MuiChip-deleteIcon {
    color: $color-secondary;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }
}

.MuiInputBase-root {
  color: $color-secondary !important;
}

.MuiFormHelperText-root {
  // help text below input fields
  font-size: 11px !important;
  color: rgba($color-secondary, $alpha: 0.7) !important;
}

.MuiToggleButton-root {
  font-family: $bodyfont !important;
  font-weight: 400 !important;
  color: $color-secondary !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  border-color: rgba($color: #242038, $alpha: 0.3) !important;
}

#add-form-disclosure {
  .MuiButtonBase-root.Mui-selected {
    background-color: lighten($color-primary, 40%) !important;
  }
}

.MuiSelect-select {
  &:focus {
    background-color: transparent !important;
  }
}

.MuiFilledInput-root.Mui-disabled {
  background-color: $color-app-bg !important;
}

// .MuiOutlinedInput-notchedOutline {
//     border-color: rgba($color: #242038, $alpha: 0.3) !important;
// }
