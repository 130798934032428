@import "../../sass/base";

.pageNotFound {
  .brand-home {
    a {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }

    .App-logo {
      height: 40px;
      margin-right: 8px;
    }

    h1 {
      .text-bold {
        font-weight: 700;
      }
    }
  }

  img {
    max-width: 100%;
    display: block;
    height: auto;
  }

  .text404 {
    text-align: center;

    h2 {
      font-family: $bodyfont;
      font-size: 2rem;
      margin-bottom: 1rem;
    }
  }

  .button-block {
    text-align: center;
  }
}
